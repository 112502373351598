body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  display: flex;
  flex-grow: 1;
  position: relative;
}

.lang-selector {
  display: flex;
  align-items: center;
  justify-content: center;

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;

    button {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
