input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
}

body {
  font-family: "PT Sans", "Helvetica", "Arial", sans-serif;
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
